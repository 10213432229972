.popup-error {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    margin: auto;
    position: relative;

    @media(max-width: $media3) {
        padding: 16px 16px 25px;
        max-width: 320px;
        overflow: hidden;
    }

    h2 {
        max-width: 330px;
        margin-bottom: 20px;
        @media(max-width: $media3) {
            padding-right: 40px;
        }
    }

    &-promo {
        margin-bottom: 30px;

        @media(max-width: $media3) {
            input {
                height: 50px;
                padding: 15px 10px;
            }

            .btn {
                width: 40px;
            }
        }
    }

    &-header {
        margin-bottom: 15px;

        h3 {
            font-size: 16px;
        }

        p {
            margin-top: 10px;
            font-weight: 500;
            line-height: 165%;
            color: #717787;
            letter-spacing: normal;
            max-width: 365px;
        }
    }

    &-values {
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        background: linear-gradient(144.48deg, rgba(194, 221, 253, 0.4) 1.66%, rgba(196, 222, 255, 0.4) 45.18%, rgba(231, 223, 255, 0.4) 97.32%);
        margin-bottom: 20px;

        @media(max-width: $media3) {
            flex-direction: column;
            gap: 20px;
            padding: 16px;
        }
    }

    &-span {
        font-size: 14px;
        font-weight: 500;
        color: #717787;
    }

    &-value {
        font-weight: 600;
        margin-top: 4px;
    }

    &-footer {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media(max-width: $media3) {
            margin-top: 20px;
        }

        .btn {
            @media(max-width: $media3) {
                height: 42px;
                font-size: 14px;
            }
        }

        .politic {}
    }

    &-radios {
        overflow: visible;
    }

    &-radio {
        margin-right: 10px;
        width: auto;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        input {
            display: none;

            &:checked+* {
                background-color: #254061;
                color: #fff;

                svg {
                    border-color: #8BBAAF;
                    background-color: #8BBAAF;
                    stroke: #fff;
                }
            }
        }

        &-wrap {
            border-radius: 5px;
            background: #F3F4F5;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 15px;
            color: #717787;
            font-weight: 500;

            svg {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                stroke: #717787;
                border: 1px solid #717787;
            }
        }
    }

    &-entity {
        margin-top: 30px;
        display: none;
    }

    &-inputs {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        &:last-child {
            margin-bottom: 0;
            gap: 6px;
        }

        .input {
            width: calc(50% - 5px);

            @media(max-width: $media3) {
                width: 100%;
            }
        }
    }

    &-info {
        margin-top: 15px;

        &-li {
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            color: #717787;
            background-image: url(/assets/img/border.svg);
            background-repeat: repeat-x;
            background-position-y: -1px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                background: #fff;
            }
        }
    }
}

[data-entity] {
    display: none;

    &.active {
        display: block;
    }
}
// requests validity price