.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(#262626, .45);
  z-index: 10;
  display: none;
  opacity: 0;
  animation-fill-mode: both;
  animation-duration: .3s;
  animation-timing-function: linear;

  &_top {
    --delay: .2s;
  }

  &_open {
    display: flex;
    animation-name: fadeIn;

    .popup-content_top {
      animation-name: moveTopIn;
      animation-delay: .3s;
    }
  }

  &_close {
    animation-name: fadeOut;

    .popup-content_top {
      animation-name: moveTopOut;
      animation-delay: 0s;
    }
  }

  &-close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    @media(max-width: $media3) {
      right: 16px;
      top: 16px;
    }

    svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: .3s;
      fill: #fff;
      background: #576371;
    }

    @media(hover) {
      &:hover {
        svg {
          background: #fff;
          fill: #254061;
        }
      }
    }

    &_white {
      width: 30px;
      height: 30px;
      svg {
        fill: #254061;
        background: #F8F9FB;
      }

      @media(hover) {
        &:hover {
          svg {
            background: #254061;
            fill: #fff;
          }
        }
      }
    }
  }

  &-content {
    &_top {
      animation-fill-mode: both;
      animation-duration: .2s;
      animation-timing-function: linear;
      transform: translateY(-100%);
    }
  }
}

.js-popup-create {
  display: none;
}