.header {
  background: #F8F9FB;
  padding: 25px 0;

  @media(max-width: $media1) {
    padding: 23px 0;
  }
  @media(max-width: $media2) {
   padding: 13px 0; 
  }
  @media(max-width: $media3) {
   padding: 7px 0; 
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: $media1) {
      gap: 45px;
    }

    @media(max-width: $media2) {
      gap: 12px;
    }
  }

  &-logo {
    display: none;

    @media(max-width: $media1) {
      display: block;
    }

    @media(max-width: $media2) {
      margin-right: 13px;
    }
    @media(max-width: $media3) {
      margin: auto;
    }

    img {
      display: block;

      @media(max-width: $media3) {
        width: 39px;
      }
    }
  }

  &-burger {
    display: none;
    @media(max-width: $media1) {
      display: block;
    }

    svg {
      width: 42px;
      height: 28px;
      display: block;
      stroke: #254061;
      @media(max-width: $media2) {
        width: 31px;
        height: 21px;
      }
    }
  }

  .btn {
    width: auto;

    &-red {
      padding: 0 40px;

      @media(max-width: $media1) {
        padding: 0 12px;
        margin-right: auto;
      }

      @media(max-width: $media2) {
        height: 42px;
        padding: 0 10px;
        font-size: 14px;
      }
      @media(max-width: $media3) {
        order: -1;
        width: 42px;
        padding: 0;
        margin-right: 0;
        span {
          display: none;
        }
      }

      svg {
        width: 18px;
        height: 18px;
        display: none;
        @media(max-width: $media3) {
          display: block;
        }
      }
    }

    &-gray {
      cursor: auto;
      height: 60px;
      padding: 0 20px;

      @media(max-width: $media1) {
        display: none;
      }
    }
  }
}