.settings {

    &-form {
        margin-top: 33px;
        max-width: 864px;
        background-color: #F8F9FB;
        padding: 20px 20px 35px;
        border-radius: 5px;

        @media(max-width: $media1) {
            max-width: 900px;
        }

        &-header {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            div.active {
                --color: #254061;
                background-color: #D2D8E3;
            }
        }

        &-group {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-inputs {

            max-width: 760px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px 16px;

            @media(max-width: $media1) {
                max-width: 100%;
            }

            @media(max-width: $media3) {
                gap: 10px;
            }

            .input {
                width: calc(50% - 8px);

                @media(max-width: $media3) {
                    width: 100%;
                }
            }
        }
    }
}

.container-buttons {
    display: flex;
    gap: 11.56px;

    button {
        width: 154.72px;
        height: 33px;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s;
        background: transparent;
        font-weight: 500;
        border-radius: 17px;
        font-size: 152x;
    }

    .active {
        background-color: #254061;
        color: #fff
    }

    .disabled {
        background: #fff;
        color: #254061;
        border: 1px #254061 solid;

    }
}

.company-edit {
    // width: 40px;
    // height: 100%;
    padding: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
}



div.save {
    width: 372px;
    height: 52px;
    margin: 0px auto;
}