.politic {
  display: block;
  cursor: pointer;

  @media(hover) {
    &:hover {
      .politic-box {
        border-color: #717787!important;
      }
    }
  }

  input {
    display: none;

    &:not(:checked) + * {
      .politic-box {
        border-color: #D9D9D9;
        svg {
          display: none;
        }
      }
    }
  }

  &-wrap {
    display: flex;
    gap: 10px;
  }

  &-box {
    transition: .3s;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    flex-shrink: 0;
    border: 1px solid #717787;
    svg {
      width: 100%;
      height: 100%;
      stroke: #254061;
    }
  }

  &-text {
    color: #717787;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: normal;

    a {
      text-decoration: underline;
      transition: .3s;
      @media(hover) {
        &:hover {
          color: #254061;
        }
      }
    }
  }

  &_required {
    input {
      display: none;
  
      &:not(:checked) + * {
        .politic-box {
          border-color: #C94959;
        }
      }
    }
  }
}