.package {
    &-header {
        max-width: 965px;
        margin-bottom: 35px;

        p {
            letter-spacing: normal;
            line-height: 165%;
            color: #717787;
            margin-top: 15px;
            font-weight: 500;
        }
    }

    &-wrapper {
        margin-left: 0;
        max-width: 1050px;
        width: 100%;
        overflow: visible;

        .swiper-wrapper {
            @media(max-width: $media3) {
                flex-wrap: wrap;
            }
        }
    }

    &-item {
        margin-right: 30px;
        width: calc(50% - 15px);
        padding: 20px 30px 20px 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 250px;
        gap: 20px;

        @media(max-width: $media1) {
            margin-right: 20px;
            padding-right: 20px;
        }

        @media(max-width: $media2) {
            width: 433px;
        }

        @media(max-width: $media3) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }

        &:last-child {
            margin: 0;
        }

        &-top {
            p {
                margin-top: 4px;
                line-height: 150%;
                font-weight: 500;
                color: #717787;
                max-width: 430px;
            }
        }

        &-status {
            display: inline-block;
            margin-bottom: 6px;
            padding: 4.5px 10px;
            background-color: #8BBAAF;
            border-radius: 20px;
            font-size: 13px;
            font-weight: 600;
            color: #fff;
        }

        &-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 130%;

            @media(max-width: $media2) {
                font-size: 20px;
            }

            @media(max-width: $media3) {
                font-size: 18px;
            }
        }

        &-span {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
            color: #717787;

            @media(max-width: $media2) {
                font-size: 12px;
            }
        }

        &-value {
            font-size: 18px;
            font-weight: 600;

            @media(max-width: $media3) {
                font-size: 16px;
            }
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media(max-width: $media3) {
                flex-wrap: wrap;
                gap: 10px;
            }

            .btn {
                height: 50px;
                width: 200px;
                padding: 0;

                @media(max-width: $media2) {
                    height: 42px;
                    font-size: 14px;
                }

                @media(max-width: $media3) {
                    width: 100%;
                }
            }
        }
    }

    &-range {
        margin-top: 50px;
        padding: 20px 20px 30px;
        background: #F8F9FB;
        border-radius: 5px;
        padding-bottom: 280px;

        @media(max-width: $media2) {
            margin-top: 40px;
        }

        @media(max-width: $media3) {
            padding: 16px 16px 280px;
        }

        &-item {
            position: relative;
            padding-right: 25px;
        }

        &-progress {
            position: relative;
            &:before {
                content: '';
                border-radius: 2px;
                background: #516A8A;
                opacity: .1;
                height: 9px;
                left: 100%;
                top: 0;
                width: 25px;
                position: absolute;
            }
            input {
                width: 100%;
            }
        }

        .package-header {
            max-width: 750px;
            @media(max-width: $media2) {
                max-width: 605px;
            }
            @media(max-width: $media3) {
                margin-bottom: 30px;
            }
        }
    }

    &-plate {
        position: absolute;
        background: linear-gradient(144.48deg, rgba(194, 221, 253, 0.4) 1.66%, rgba(196, 222, 255, 0.4) 45.18%, rgba(231, 223, 255, 0.4) 97.32%);
        border-radius: 5px;
        padding: 10px;
        width: 175px;
        right: calc(var(--pos) - 175px / 2);
        transform: translateX(var(--left, 0));
        top: 100%;
        margin-top: 45px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-span {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;
            font-weight: 500;
            color: #717787;
        }

        &-value {
            margin-top: 2px;
            font-size: 14px;
            font-weight: 600;
        }

        .btn {
            font-size: 15px;
            height: 32px;
        }
    }
}

.range {
    &-single {
        position: relative;
        height: 9px;
        background: rgba(#254061, .1);
        border-radius: 2px;
    }

    &-input {
        position: absolute;
        bottom: 0;
        width: 100%;

        input {
            height: 100%;
            cursor: pointer;
            position: absolute;
            width: 100%;
            top: 0;
            appearance: none;
            opacity: 0;
        }
    }

    &-progress {
        height: 100%;
        left: 0%;
        right: 0%;
        position: absolute;
        border-radius: 2px;
        background: #516A8A;
    }

    &-thumb {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: #254061;
        box-shadow: -3px 4px 15px rgba(37, 64, 97, 0.1);
        transform: rotate(-45deg);
        top: 50%;
        right: var(--pos);
        transform: translate(50%, -50%) rotate(45deg);
    }

    &-number {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: space-between;

        &-item {
            width: 10px;
            height: 10px;
            position: relative;

            &.active {
                &:before {
                    opacity: 0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 2px;
                background-color: #E1E2E3;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                content: attr(data-value);
                position: absolute;
                top: 100%;
                left: 50%;
                white-space: nowrap;
                transform: translate(-50%, 17px);
                font-weight: 500;
                font-size: 14px;
                color: #717787;
                @media(max-width: $media3) {
                    font-size: 12px;
                }
            }

            @media(max-width: $media3) {
                &:not(:first-child):not(:last-child) {
                    opacity: 0;
                }
            }

            &._hidden {
                @media(max-width: $media1) {
                    opacity: 0;
                }
            }
        }
    }
}

#swiper-slide1{
    background-image: linear-gradient(112.85deg, rgba(215, 193, 255, 0.3) 0%, rgba(244, 168, 175, 0.3) 55.08%, rgba(255, 203, 170, 0.3) 101.07%);
}
#swiper-slide2{
    background-image: linear-gradient(144.48deg, rgba(194, 221, 253, 0.4) 1.66%, rgba(196, 222, 255, 0.4) 45.18%, rgba(231, 223, 255, 0.4) 97.32%);
}