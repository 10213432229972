.support {
    &-form {
        max-width: 746px;
        background-color: #F8F9FB;
        border-radius: 5px;
        padding: 20px 20px 35px;

        @media(max-width: $media2) {
            padding-bottom: 45px;
        }

        @media(max-width: $media3) {
            padding: 16px;
            min-height: 313px;
        }

        &-header {
            margin-bottom: 20px;

            p {
                margin-top: 15px;
                line-height: 140%;
                font-weight: 500;
                max-width: 400px;
                color: #717787;
                max-width: 32.9em;
            }
        }

        &-mask {
            max-width: 432px;
        }

        .input {
            textarea {
                height: 160px;
            }
            &.error {
                margin-bottom: 20px;
            }
        }

        .politic {
            margin-top: 10px;
        }

        .btn {
            margin-top: 30px;

            @media(max-width: $media2) {
                margin-top: 20px;
                height: 42px;
                font-size: 14px;
            }
        }
    }
}