.generate {
    &-form {
        max-width: 746px;
        background-color: #F8F9FB;
        border-radius: 5px;
        padding: 20px;
        min-height: 344px;

        @media(max-width: $media1) {
            max-width: 100%;
            min-height: auto;
            padding-bottom: 25px;
        }

        @media(max-width: $media2) {
            padding: 20px 16px;
        }

        @media(max-width: $media3) {
            padding: 16px;
            min-height: 313px;
        }

        &-header {
            margin-bottom: 20px;


            p {
                margin-top: 20px;
                line-height: 140%;
                font-weight: 500;
                max-width: 400px;
                color: #717787;

                @media(max-width: $media2) {
                    max-width: 25em;
                }

                a {
                    color: #254061;
                    text-decoration: underline;
                }
            }
        }

        &-mask {
            max-width: 432px;

            @media(max-width: $media1) {
                max-width: 420px;
            }

            @media(max-width: $media2) {
                max-width: 100%;
            }
        }

        .input {
            @media(max-width: $media2) {
                max-width: 305px;
            }
            &.error {
                margin-bottom: 20px;
            }
        }

        .politic {
            margin-top: 10px;

            @media(max-width: $media2) {
                max-width: 435px;
            }

            &-box {
                width: 26px;
                height: 26px;
            }

            &-text {
                font-weight: 500;
                font-size: 16px;

                @media(max-width: $media2) {
                    font-size: 14px;
                }
            }
        }

        .btn {
            margin-top: 30px;

            @media(max-width: $media2) {
                margin-top: 20px;
                height: 42px;
                font-size: 14px;
                max-width: 305px;
            }
        }
    }
}