.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out
}

.swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity
}

.swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-fade .swiper-slide-active,.swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}
.swiper-button-next {
  svg {
    transform: rotate(180deg);
  }
}