.popup-call {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  background: #FFFFFF;
  padding: 30px 20px 20px;
  overflow: hidden;

  @media(max-width: $media3) {
    max-width: 320px;
    padding: 16px;
  }

  &-header {
    margin-bottom: 20px;

    h3 {
      padding-right: 40px;
    }
    p {
      font-size: 15px;
      margin-top: 16px;
      line-height: 165%;
      @media(max-width: $media3) {
        font-size: 14px;
      }
    }
  }

  &-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .input {
      width: calc(50% - 5px);
      flex-grow: 1;
      @media(max-width: $media3) {
        width: 100%;
      }
    }
  }

  .btn {
    @media(max-width: $media2) {
      height: 42px;
      font-size: 14px;
    }
  }

  .politic {
    margin-top: 10px;
    padding: 0 15px;
    @media(max-width: $media3) {
      font-size: 12px;
      &-wrap {
        gap: 5px;
      }
    }
  }

  .page-form-ok {
    .popup-call-header {}
  }
}