.navbar {
    width: 405px;
    flex-shrink: 0;
    @media(max-width: $media1) {
        // margin-left: auto;
        width: 100%;
    }

    @media(min-width: $mediaMin1) {
        display: block;
        position: relative;
        opacity: 1;
        animation: none!important;
    }

    &-wrapper {
        position: relative;
        width: 100%;
        background: $mainDark;
        min-height: 100%;
        @media(max-width: $media1) {
            padding-top: 55px;
            width: 405px;
            height: min-content;
            padding-bottom: 30px;
        }
        @media(max-width: $media3) {
            padding-top: 0;
            width: 100%;
        }
    }

    &-header {
        border-bottom: 1px solid rgba(#fff, .3);
        padding: 20px 30px;
        @media(max-width: $media1) {
            display: none;
        }

        &-popup {
            display: none;
            @media(max-width: $media1) {
                display: block;
            }

            .header {
                background: none;
                display: none;
                @media(max-width: $media3) {
                    display: block;
                }

                &-wrapper {
                    @media(max-width: $media3) {
                        padding-right: 43px;
                    }
                }
            }
        }
    }

    &-logo {
        display: flex;
        gap: 16px;

        img {
            display: block;
            transform: translateY(-3px);
        }

        &-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 120%;
            color: #fff;
            display: block;
        }

        &-text {
            display: block;
            font-size: 12px;
            line-height: 140%;
            color: #E9E9E9;
            margin-top: 3px;
        }
    }

    &-body {
        margin-top: 25px;
        @media(max-width: $media1) {
            margin-top: 0;
        }
    }

    &-nav {
        &-link {
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 20px;
            font-weight: 500;
            color: #fff;
            position: relative;
            padding: 14px 30px;
            min-height: 50px;
            margin-bottom: 10px;
            transition: .3s;
            @media(max-width: $media3) {
                font-size: 14px;
                margin-bottom: 0;
                padding-left: 20px;
                padding-right: 20px;
            }

            @media(hover) {
                &:hover {
                    background: rgba(#fff, .2);
                }
            }

            &.active {
                background: rgba(#fff, .2);
            }

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                fill: none;
                width: 22px;
                height: 24px;
            }
        }
    }

    &-footer {
        padding: 0 30px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media(max-width: $media3) {
            margin-top: 25px;
            padding: 0 20px;
            gap: 5px;
        }

        .btn {
            @media(max-width: $media3) {
                height: 42px;
                font-size: 14px;
            }
        }

        .btn-white {
            display: none;
            @media(max-width: $media1) {
                display: flex;
            }
        }
    }

    .popup-close {
        top: 10px;
        @media(max-width: $media3) {
            top: 16px;
        }
    }
}