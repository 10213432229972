h1,
.h1 {
  font-size: 48px;
  margin-bottom: 30px;

  @media(max-width: $media1) {
    font-size: 38px;
  }

  @media(max-width: $media2) {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

h2,
.h2 {
  font-size: 20px;

  @media(max-width: $media2) {
    font-size: 16px;
  }
}

h3,
.h3 {
  font-size: 18px;
}