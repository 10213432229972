.input {
  position: relative;

  &-wrap {
    position: relative;
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
    color: #717787;
    line-height: 162%;
    margin-bottom: 6px;

    @media(max-width: $media2) {
      font-size: 16px;
      margin-bottom: 9px;
    }

    @media(max-width: $media3) {
      font-size: 15px;
      margin-bottom: 4px;
    }
  }

  @media(hover) {
    &:hover {

      input,
      textarea,
      .input-value {
        border-color: #717787;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    /* <-- Apparently some margin are still there even though it's hidden */

  }

  input,
  textarea,
  &-value {
    font-weight: 500;
    transition: .3s;
    display: block;
    width: 100%;
    outline: none;
    background: none;
    height: 52px;
    padding: 14px 15px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    background: none;

    @media(max-width: $media2) {
      padding-top: 11px;
      padding-bottom: 11px;
      height: 42px;
    }


    &::placeholder {
      transition: .3s;
      color: #717787;
    }
  }

  textarea {
    resize: none;
    height: 120px;

    @media(max-width: $media3) {
      height: 160px;
    }
  }

  &_focus {

    input,
    textarea {
      border-color: #717787;
    }

    .input-label {
      font-size: 12px;
      top: 0;
      color: #BDBDBD;

      @media(max-width: $media2) {
        font-size: 11px;
      }
    }
  }

  &-value {
    padding-top: 16px;
    color: #717787;

    @media(max-width: $media2) {
      padding-top: 14px;
    }
  }

  &-label {
    font-weight: 500;
    transition: .3s;
    pointer-events: none;
    position: absolute;
    left: 15px;
    top: 14px;
    line-height: normal;
    color: #717787;
  }

  &-error {
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
    color: #C94959;
    display: none;
    line-height: normal;
    padding: 0 15px;
    line-height: 145%;
  }

  &.error {
    margin-bottom: 10px;

    &:not(.input_focus) {
      .input-label {
        color: #C94959;
      }

      input::placeholder {
        color: #C94959;
      }
    }

    .input-error {
      display: block;
    }

    input,
    textarea,
    .input-value {
      border-color: #C94959;
    }
  }

  &-right {
    display: flex;
    position: absolute;
    top: var(--pos, 6px);
    bottom: var(--pos, 6px);
    right: var(--pos, 6px);

    @media(max-width: $media2) {
      --pos: 4px;
    }

    .btn {
      height: 100%;
      padding: 0;
      flex-shrink: 0;
      width: 40px;

      @media(max-width: $media2) {
        width: 35px;
      }
    }
  }

  &-edit {
    svg {
      width: 14px;
      height: 15px;
    }
  }

  &-eye {
    --stroke: #717787;

    &.active {
      --stroke: none;
    }

    svg {
      width: 20px;
      height: 16px;
      stroke: #717787 !important;
    }
  }

  &-load {
    input {
      display: none;
    }

    svg {
      width: 17px;
      height: 17px;
    }
  }

  &-promo {
    background-color: #F8F9FB;

    svg {
      width: 26px;
      height: 26px;
      stroke: #BDBDBD;
    }
  }
}