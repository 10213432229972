.popup-tariff {
    &-form {
        background-color: #F8F9FB;
        border-radius: 5px;
        padding: 16px 20px;

        @media(max-width: $media3) {
            padding: 16px;
        }

        h3 {
            margin-bottom: 20px;

            @media(max-width: $media2) {
                font-size: 16px;
            }
        }

        .politic {
            margin-top: 10px;
            padding: 0 15px;

            &-wrap {
                @media(max-width: $media3) {
                    gap: 6px;
                }
            }

            &-text {
                @media(max-width: $media3) {
                    font-size: 12px;
                }
            }
        }
    }

    &-inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @media(max-width: $media3) {
            gap: 6px;
        }

        >* {
            width: calc(50% - 5px);

            @media(max-width: $media3) {
                width: 100%;
            }
        }

        .btn {
            @media(max-width: $media2) {
                font-size: 14px;
                height: 42px;
            }

            @media(max-width: $media3) {
                margin-top: 10px;
            }
        }
    }
}

#popup-tariff {
    .popup-bay-header {

        h2,
        p {
            max-width: 100%;
        }

        h2 {
            margin-bottom: 5px;
        }
    }
    .package-range-item {
        padding-bottom: 236px;
    }
    .range-number-item {
        @media(max-width: $media3) {
            &:not(:first-child):not(:last-child) {
                opacity: 0;
            }
        }
        &._hidden {
            opacity: 0;
        }
    }
}