*,
*:before,
*:after {
  box-sizing: border-box;
}

img,
.btn {
  user-select: none;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  letter-spacing: 0.02em;
}

[data-modal] {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
}

[data-gallery] {
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  overflow-x: hidden;
  position: relative;
  font-size: $size16;
  color: #254061;
  font-family: 'Montserrat', sans-serif;
  // !!!!!
  overflow: hidden;
  // !!!!!
  height: 100vh;
  letter-spacing: 0.02em;
}

#app {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  display: flex;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media(max-width: $media1) {
    width: 100%;
  }
}

.wrapper-scroll {
  flex-grow: 1;
  height: 10%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #B4BECA;
    border-radius: 5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #2D3C4E;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  @media(max-width: $media3) {
    padding: 0 15px;
  }
}

.page-form {
  position: relative;

  &.active {
    .page-form-ok {
      opacity: 1;
      visibility: visible;
    }

    .page-form-send {
      opacity: 0;
      visibility: hidden;
    }
  }

  &-ok {
    padding: inherit;
    margin-top: 35px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

  }
}