.application {
    &-item {
        background-color: #F8F9FB;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 25px;

        @media(max-width: $media3) {
            padding: 16px;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-loading {
            display: flex;
            justify-content: space-between;

            @media(max-width: $media1) {
                flex-direction: column;
                gap: 40px;
            }
        }

        &-history {
            h2 {
                margin-bottom: 20px;
            }
        }
    }

    &-group {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 15px;
        }
    }

    &-file {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 20px;
        font-weight: 500;
        line-height: 0.9em;

        &:last-child {
            margin-bottom: 0;
        }

        &-svg {
            position: relative;

            svg {
                width: 37px;
                height: 32px;
                display: block;
            }
        }

        &-name {
            font-size: 15px;
            color: #254061;

            @media(max-width: $media2) {
                font-size: 14px;
            }
        }

        &-size {
            font-size: 14px;
            color: #BDBDBD;

            @media(max-width: $media2) {
                font-size: 13px;
            }
        }
    }

    &-example {
        width: 100%;
        max-width: 660px;

        img {
            display: block;
            max-width: 100%;
            border-radius: 5px;
        }
    }

    &-form {
        width: 100%;
        max-width: 610px;

        &-mask {
            max-width: 432px;
        }

        &-footer {
            .btn {
                margin-top: 20px;

                @media(max-width: $media2) {
                    height: 42px;
                    font-size: 14px;
                }
            }

            .politic {
                margin-top: 10px;
            }
        }
    }

    &-header {
        margin-bottom: 20px;

        @media(max-width: $media2) {
            margin-bottom: 10px;
        }

        p {
            margin-top: 16px;
            font-weight: 500;
            line-height: 165%;
            color: #717787;
            letter-spacing: normal;
        }
    }
}

.watch-error {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #254061;
    font-weight: 500;
    padding-bottom: 15px;
}