.history-card {
    padding: 0 20px;
    margin-bottom: 20px;

    @media(max-width: $media1) {
        margin-bottom: 10px;
    }

    @media(max-width: $media2) {
        margin-bottom: 0;
    }

    @media(max-width: $media3) {
        padding: 0 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:nth-child(odd) {
        background: #F8F9FB;
    }

    &.active {

        .history-card-header {
            border-color: #D9D9D9;
        }

        .history-card-body {
            display: block;
        }

        .history-card-watch {
            svg {
                transform: rotate(180deg);
            }

            span:before {
                content: attr(data-after);
            }
        }
    }

    &-header {
        display: flex;
        align-items: center;
        gap: 45px;
        padding: 40px 0 20px;
        border-bottom: 1px solid transparent;

        @media(max-width: $media1) {
            flex-wrap: wrap;
            gap: 20px 30px;
            padding-top: 30px;
        }

        @media(max-width: $media2) {
            padding-top: 20px;
        }

        @media(max-width: $media3) {
            flex-direction: column;
            gap: 15px;
        }

        .btn {
            width: auto;

            @media(max-width: $media1) {
                width: 260px;
                flex-grow: 1;
            }

            @media(max-width: $media2) {
                height: 42px;
            }

            @media(max-width: $media3) {
                width: 100%;
            }
        }
    }

    &-buttons {
        display: flex;
        align-items: center;
        gap: 45px;
        flex-grow: 1;
        

        @media(max-width: $media1) {
            flex-wrap: wrap;
            gap: 30px;
        }

        @media(max-width: $media3) {
            gap: 15px;
            width: 100%;
        }
    }

    &-info {
        flex-grow: 1;
        min-width: 500px;
        width: calc(100% - 600px);
        @media(max-width: $media2) {
            width: 100%;
            min-width: auto;
        }
    }

    &-number {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &-id {
        font-size: 18px;
        font-weight: 600;
        color: #254061;
    }

    &-status {
        padding: 2.5px 12px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        background: #6FA598;
        height: 20px;
    }

    &-text {
        margin-top: 10px;
        color: #717787;
        font-weight: 500;
    }

    &-date {
        margin-top: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #A1A1A1;
    }

    &-watch {
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #254061;
        font-weight: 500;

        @media(max-width: $media1) {
            width: 260px;
            flex-grow: 1;
            justify-content: center;
        }

        @media(max-width: $media3) {
            order: 1;
            width: 100%;
        }

        span {
            &:before {
                content: attr(data-before);
            }
        }

        svg {
            width: 13px;
            height: 6px;
            stroke: #254061;
        }
    }

    &-body {
        overflow: hidden;
        display: none;
        transition: .3s;

        &-contant {
            padding: 30px 0;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;

            @media(max-width: $media2) {
                display: none;
            }
        }

        &-mobile {
            display: none;

            @media(max-width: $media2) {
                display: block;
            }

            .history-card-title {
                margin-bottom: 16px;
            }
        }

        &-ul {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }

        &-li {
            width: calc(50% - 10px);
            position: relative;
            padding-left: 20px;
            color: #717787;

            @media(max-width: $media3) {
                width: 100%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
            }

            span {
                line-height: 110%;
                display: block;
                font-weight: 600;
                max-width: 255px;
            }

            p {
                font-size: 14px;
                line-height: 140%;
                margin-top: 5px;
            }
        }
    }

    &-title {
        width: 100%;
        margin-bottom: 30px;

        span {
            font-weight: 600;
            color: #0F325C;
        }

        p {
            margin-top: 10px;
            font-weight: 500;
            color: #717787;
        }
    }

    &-item {
        width: 50%;
        position: relative;
        --pH: 40px;
        --pV: 40px;

        @media(max-width: $media1) {
            --pH: 20px;
            --pV: 30px;
        }

        &:nth-child(1) {
            padding-bottom: var(--pV);
            padding-right: var(--pH);
            border-bottom: 0.5px dashed #D9D9D9;
            border-right: 0.5px dashed #D9D9D9
        }

        &:nth-child(2) {
            padding-bottom: var(--pV);
            padding-left: var(--pH);
            border-bottom: 0.5px dashed #D9D9D9;
            border-left: 0.5px dashed #D9D9D9
        }

        &:nth-child(3) {
            padding-top: var(--pV);
            padding-right: var(--pH);
            border-top: 0.5px dashed #D9D9D9;
            border-right: 0.5px dashed #D9D9D9
        }

        &:nth-child(4) {
            padding-top: var(--pV);
            padding-left: var(--pH);
            border-top: 0.5px dashed #D9D9D9;
            border-left: 0.5px dashed #D9D9D9
        }

        &-image {
            width: 415px;
            height: 401px;
            display: inline-block;
            position: relative;
            @media(max-width: $media1) {
                width: 391px;
            }

            img {
                display: block;
            }

            &-line {
                position: absolute;
                &.bottom  {
                    left: 25px;
                    top: 134px;
                }
                &.right {
                    top: 43px;
                    left: 113px;
                    @media(max-width: $media1) {
                        top: 54px;
                    }
                }
                
            }
        }

        &-value {
            width: 161px;
            height: 161px;
            border-radius: 50%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            font-size: 12px;
            line-height: 120%;
            color: #41546C;
            z-index: 1;
            background: #B4BECA;

            &._one {
                top: 0;
                left: 0;
                color: #fff;
                background: #627184;
            }

            &._two {
                top: 115px;
                right: 0;
            }

            &._three {
                bottom: 0;
                left: 49px;
            }

            &.active {
                background: #F8F9FB;
                padding: 20px;
            }

            &-text {
                span {
                    font-weight: 600;
                }
            }

            &-value {
                font-size: 18px;
                font-weight: 600;
                line-height: 140%;
                white-space: nowrap;
                margin-top: 2px;
            }

        }

        &-text {
            position: absolute;
            left: 220px;
            top: 34px;
            max-width: 470px;
            font-size: 14px;
            line-height: 120%;
            color: #717787;

            @media(max-width: $media1) {
                position: relative;
                left: 0;
                top: 0;
                margin-top: 30px;
            }

            p {
                margin-bottom: 1.2em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-category {
        display: flex;
        gap: 20px;

        @media(max-width: $media1) {
            flex-direction: column;
            gap: 10px;
        }

        &-status {
            flex-shrink: 0;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            background: #6FA598;
            font-weight: 600;
            color: #fff;

            span {
                display: block;
                font-size: 14px;
                line-height: 120%;
            }

            p {
                text-transform: uppercase;
                line-height: 140%;
            }
        }

        &-body {
            font-size: 14px;
            color: #717787;
            line-height: 120%;
        }

        &-name {
            font-weight: 600;
        }

        &-text {
            margin-top: 4px;
            max-width: 325px;
        }

        &-progress {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 12px;
            line-height: 120%;
            color: #717787;

            @media(max-width: $media1) {
                max-width: 365px;
            }

            &-line {
                width: 100%;
                height: 20px;
                background: linear-gradient(90deg, #49897A 0%, #6FA598 26%, #DF9B36 50%, #CB6772 74%, #B64955 100%);
                border-radius: 5px;
                margin-bottom: 6px;
            }

            &-last {
                text-align: right;
            }
        }
    }

    &-forecast {
        display: flex;
        gap: 20px;

        @media(max-width: $media1) {
            flex-direction: column;
            grid-area: 10px;
        }

        &-body {
            font-size: 14px;
            line-height: 120%;
            color: #717787;
            max-width: 430px;
        }

        &-list {
            margin-top: 10px;
        }

        &-item {
            margin-bottom: 6px;
            position: relative;
            padding-left: 90px;
            max-width: 360px;

            span {
                position: absolute;
                top: 0;
                left: 0;
                width: 86px;

                &:after {
                    content: '—';
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

            &:last-child {
                margin-bottom: 6px;
            }
        }
    }

    &-circle {
        width: 160px;
        height: 160px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        gap: 2px;
        position: relative;
        z-index: 1;

        &-text {
            font-size: 12px;
            line-height: 120%;
            color: #41546C;

            span {
                font-weight: 600;
                font-size: 13px;
            }
        }

        &-value {
            font-size: 20px;
            font-weight: 600;
            line-height: 140%;
            color: #41546C;
        }
    }

    &-svg {
        position: absolute;
        width: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        svg {
            fill: none;
            position: absolute;
            width: 100%;
            width: 100%;
            left: 0;
            right: 0;
            transform: rotateY(180deg) rotateZ(90deg);
        }
    }
}