@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "~normalize.css/normalize";

@import "var";
@import "layout";
@import "title";
@import "swiper";
@import "animation";
@import "mixin";

@import "popup";
// @import "gallery";

@import "components/app";
@import "block/app";
@import "navbar";
@import "header";
@import "popup/app";
// @import "footer";