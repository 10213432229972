.btn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  transition: .3s;
  background: transparent;
}

.btn {
  height: 52px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 28px;
  color: var(--color);
  background: var(--background);
  border-color: var(--border-color);
  letter-spacing: normal;

  @media(hover) {
    &:hover {
      color: var(--color-hover, var(--color));
      background: var(--background-hover, var(--background));
      border-color: var(--border-color-hover, var(--border-color));

      svg {
        stroke: var(--color-hover, var(--color));
        fill: var(--color-hover, var(--color));
      }
    }
  }

  svg {
    width: 16px;
    height: 18px;
    display: block;
    transition: .3s;
    stroke: var(--color);
    fill: var(--color);
  }

  &-red {
    --background: #C94959;
    --background-hover: #B83646;
    --color: #fff;
    font-family: inherit;

    &-border {
      border-width: 1px;
      border-style: solid;
      --background-hover: #C94959;
      --color-hover: #fff;
      --color: #C94959;
      --border-color: #C94959;
      --background: transparent;
    }
  }

  &-gray {
    --background: #EBEFF6;
    --background-hover: #EBEFF6;
    --color: #717787;
    --background-hover: #D2D8E3;
  }

  &-white {
    --background: #fff;
    --color: #254061;
    --background-hover: #969DA7;
    --color-hover: #fff;

    &-border {
      --background: transparent;
      --color: #fff;
      border-width: 1px;
      border-style: solid;
      --border-color: #fff;
      --background-hover: #969DA7;
      --border-color-hover: #969DA7;
    }
  }

  &-blue {
    --background: #254061;
    --color: #fff;

    &-border {
      border-width: 1px;
      border-style: solid;
      --background-hover: #254061;
      --color-hover: #fff;
      --color: #254061;
      --border-color: #254061;
      --background: transparent;
    }
  }
}

.btn-loading::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: #C94959; 
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin-left: -8px;
  vertical-align: middle;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.btn-loading:hover::after {
  border-top-color: #fff; 
}

.btn-loading .btn-text {
  display: none;
}

.btn-red-border:hover {
  background-color: #C94959;
  color: #fff;
}