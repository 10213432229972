.popup-password {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  max-width: 412px;
  border-radius: 5px;
  background: #FFFFFF;
  padding: 20px;
  overflow: hidden;

  @media(max-width: $media3) {
    max-width: 320px;
    padding: 16px;
  }

  &-header {
    margin-bottom: 20px;

    h2 {
      padding-right: 40px;
    }

    p {
      font-size: 15px;
      margin-top: 16px;
      line-height: 165%;

      @media(max-width: $media3) {
        font-size: 14px;
      }
    }
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 30px;
    @media(max-width: $media3) {
      margin-bottom: 20px;
    }
  }

  &-description {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 140%;
    color: #717787;
    letter-spacing: 0.01em;
  }

  .btn {
    @media(max-width: $media2) {
      height: 42px;
      font-size: 14px;
    }
  }
}