$media1: 1919px;
$mediaMin1: 1920px;
$media2: 959px;
$mediaMin2: 960px;
$media3: 719px;
$mediaMin3: 720px;

:root {
  --size16: 16px;
  --caption: 14px;
  @media(max-width: $media2) {
    --size16: 14px;
    --caption: 12px;
  }
}

$size16: var(--size16);

$mainDark: #2D3C4E;